<template>
  <div class="image_upload">
    <el-upload
      action
      list-type="picture-card"
      :limit="5"
      class="avatar-uploader"
      accept="image/*"
      :http-request="upload"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      multiple
      :on-exceed="handleExceed"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import { uploadAdmin } from '@/api/common'
export default {
  props: {
    fileList: {
      type: Array
    }
  },
  data() {
    return {
      dialogImageUrl:[],
      dialogVisible: false,
      testList: [],
      list: []
    }
  },
  methods: {
    handleExceed(files, fileList) {
      // console.log(files, fileList)
      this.$messageError('最多只能上传五张图片')
    },
    handleRemove(file, fileList) {
      this.$emit('removeImg', file)
      // console.log(fileList,"fileList")
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async upload(file) {
      // this.fileList = []
      this.testList = []
      const form = new FormData()
      form.append('files', file.file)
      const { data: res } = await uploadAdmin(form)
      if (res.resultCode === 200) {
        this.testList = this.fileList
        this.testList.push({
          name: file.file.name,
          uid: file.file.uid,
          url: res.data
        })
        this.list = this.list.concat(res.data)
        this.$emit('updateImgShowTwo', this.testList)
        this.$emit('updateImgTwo', this.list)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-icon-zoom-in{
  display: none;
}
/deep/ .el-upload-list__item {
  transition: none !important;
}
.image_upload {
  .avatar-uploader{
    display: flex;
    ul{
      display: flex;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  /deep/.el-upload--picture-card {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 88px;
    height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  .addinfo_title {
    font-size: 14px;
    color: #999;
  }
}
</style>
