<template>
  <div class="user">
    <div class="title">{{ editFlag ? '详细话题' : '新增话题' }}</div>
    <div class="item_content" v-if="!editFlag">
      <el-form :model="queryInfo" label-width="100px" ref="form" :rules="rules">
        <el-form-item label="信息名称:" prop="messageName">
           <el-input v-model="queryInfo.messageName" placeholder="请输入信息名称" clearable style="width:280px"/>
        </el-form-item>
        <el-form-item label="信息图片:" prop="messageImage">
          <div class="ImgArea">
            <div>
              <upload-image
              :fileList="imageUrlList"
              @updateImgTwo="updateImgTwo"
              @updateImgShowTwo="updateImgShowTwo"
              @removeImg="emitImgRemove"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item label="信息描述" prop="messageDescribe">
          <div class="editBar">
            <editorbar v-model="queryInfo.messageDescribe"/>
          </div>
        </el-form-item>
        <div class="bottom_tip">
          (图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
        </div>
      </el-form>
      <div class="btn_area">
        <div class="btn" @click="addOne('form')">保存并推送</div>
        <div class="cancel" @click="backPlace()" style="margin-left:20px">取消</div>
      </div>
    </div>
    <div class="item_content dataList" v-else>
      <el-descriptions :column="1">
        <el-descriptions-item label="信息名称">{{queryInfo.messageName}}</el-descriptions-item>
        <el-descriptions-item label="信息图片">
          <div class="ImgArea">
              <div v-for="(item,index) in imageUrlList" :key="index" class="showImg">
                <img :src="item.url" alt="">
              </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="信息描述"></el-descriptions-item>
      </el-descriptions>
      <div class="PI">
        <textFlow :info="queryInfo.messageDescribe" :lineClamp='4' :hiddenBtn="true" class="text_area"></textFlow>
      </div>
    </div>
  </div>
</template>


<script>
import textFlow from '@/components/textflow.vue'
import UploadImage from '@/components/UploadImage.vue'
import Editorbar from '../../../../components/Editorbar.vue';
import { getDetail, addInfo } from '@/api/infoPush.js'
export default {
  components: { UploadImage, Editorbar, textFlow},
  data () {
    return {
      queryInfo:{},
      imgUrlShow:[],
      imageUrlList:[],
      imgList:[],
      editFlag:false,
      rules:{
        messageName:[{ required: true, message: '请输入信息名称', trigger: 'blur' }],
        messageImage:[{ required: true, message: '请选择图片', trigger: 'blur' }],
        messageDescribe:[{ required: true, message: '请输入信息描述', trigger: 'blur' }]
      }
    }
  },
  mounted(){
    if(this.$route.query.id){
      this.getDetail(this.$route.query.id)
      this.editFlag = true
    }
  },
  methods: {
    async getDetail(id){
      const { data: res } = await getDetail({id:id})
      // console.log(res.data.messageDescribe,"123");
      if(res.data.messageImage) res.data.messageImage = res.data.messageImage.split(',')
      this.queryInfo = res.data
      this.imageUrlList = res.data.messageImage.map(el => {
        return {
          url: el,
          name: el
        }
      })
      // console.log(this.queryInfo,"123123");
    },
    addOne(formName){
      this.queryInfo.messageImage = []
      this.imageUrlList.map(item=>{
        this.queryInfo.messageImage.push(typeof(item.url) == 'object' ? item.url[0] : item.url)
      }) 
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await addInfo(this.queryInfo)
          if (res.resultCode === 200) {
            this.$message.success('添加成功!')
            this.outPlace(res.data)
          }
        } else {
          this.$message.error('请填写必要的选项!')
          return false;
        }
      });
    },
    backPlace(){
      this.$router.push({
        path: `/businessHub/infoPush`,
      })
    },
    outPlace(id){
      this.$router.push({
        path: `/infoPush/userInfoPush?id=${id}`,
      })
    },
    //多个图片上传
    updateImgTwo(val) {
      this.imgList = val
      // console.log(this.imgList,'imgList');
    },
    //多个图片上传显示
    updateImgShowTwo(val) {
      this.imageUrlList = val
      // console.log(this.imageUrlList,"updateImgShowTwo");
    },
    //多个图片上传移除
    emitImgRemove(val) {
      this.imageUrlList = this.imageUrlList.filter((item) => {
        return item !== val
      })
      // console.log( this.imageUrlList,"ad");
    }
  },
}
</script> 

<style scoped lang="less">

.PI {
  background: #fbf9f9 !important;
  width: 700px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  overflow: hidden;
  margin-bottom: 50px;
  .text_area {
    margin: 20px 20px 20px 20px;
    background: #fbf9f9 !important;
  }

}
.showImg{
  width: 64px;
  height: 64px;
  margin-left: 10px;
  img{
    width: 100%;
    height: 100%;
  }
}
.user{
/deep/.w-e-text-container {
  z-index: 1 !important;
}

/deep/.w-e-menu {
  z-index: 2 !important;
}

/deep/.w-e-toolbar{
  z-index: 2 !important;
}
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  border-radius: 12px;
  background: #ffffff;
  .dataList{
    margin: 20px 0 0 40px !important;
  }
  .item_content{
    margin: 20px 0px 0px 20px;
    .bottom_tip{
      font-size: 12px;
      color: #7C71E1;
      margin: 10px 0px 40px 100px;
    }
    .editBar{
      width: 800px;
    }
  }
  .title{
    width: 100%;
    height: 40px;
    text-indent: 1.5em;
    border-bottom:1px solid #EFEFEF;
    margin: 16px 20px 0px 20px;
    font-size: 18px;
    line-height: 25px;
    color: #968DE2;
  }
}
.ImgArea{
  display: flex;
  position: relative;
  .cancel{
    cursor: pointer;
    position: absolute;
    font-size: 18px;
    top: -5px;
    right: 5px;
  }
}
.btn_area{
    margin: 250px 0 100px 60px;
    width: 100%;
    display: flex;
    .cancel,.btn{
      text-align: center;
      line-height: 40px;
      width: 136px;
      height: 40px;
      border-radius: 4px;
      cursor: pointer;
    }
    .btn{
      color: #fff;   
      background: #7C71E1;
    }
    .cancel{
      border: 1px solid #7C71E1;
      background: #fff;
      color: #7C71E1;
    }
  }
</style>
